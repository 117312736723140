(function () {
	function embedYouTubeAPI() {
		var tag = document.createElement('script');

		tag.src = "https://www.youtube.com/iframe_api";
		var firstScriptTag = document.getElementsByTagName('script')[0];
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
	}

	function onYouTubeIframeAPIReady(vid) {
		var player;
		var embedId = vid.dataset.embedId;

		player = new YT.Player(vid, {
			height: '390',
			width: '640',
			videoId: embedId,
			playerVars: {
				'playsinline': 1,
				'modestbranding': 1,
				'rel': 0,
				'controls': 0
			}
		});
	}

	function embedYouTubeVids() {
		var vids = document.querySelectorAll('[data-embed-id]');

		window.onYouTubePlayerAPIReady = function () {
			vids.forEach(v => {
				if (v.dataset.embedId != ''){
					onYouTubeIframeAPIReady(v);
				}				
			});
		};		
	}

	embedYouTubeAPI();
	embedYouTubeVids();
}());