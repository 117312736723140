export default function menuToggle() {
	var menu = document.querySelector(".site-header");
	var menuToggle = document.querySelector(".site-header .menu-toggle");

	if (menuToggle == null) {
		return;
	}

	menuToggle.addEventListener("click", function (e) {
		e.preventDefault();

		menu.classList.toggle("active");
		document.querySelector("body").classList.toggle("no-scroll");

		//DO ARIA STUFF
	});
}

function mobileMenu() {
	if (document.querySelector(".menu__sub")) {
		var subCloseTemp = document.createElement("div");
		subCloseTemp.innerHTML =
			'<li class="menu__item"><a class="menu__link sub__close" href="#"><span class="menu__expand"></span></i> Back</a></li>';

		var siteMenu = document.querySelector(".site-nav .menu");
		var menuSubs = siteMenu.querySelectorAll(".menu__sub");
		var menuExpandLinks = siteMenu.querySelectorAll(".menu__link .menu__expand");

		menuSubs.forEach(el => {
			el.insertAdjacentHTML("afterbegin", subCloseTemp.innerHTML);
		});

		// Close out sub menu
		var menuClose = siteMenu.querySelectorAll(".sub__close");

		menuClose.forEach(el => {
			el.addEventListener("click", e => {
				e.preventDefault();

				el.parentNode.parentNode.classList.remove("active");
			});
		});

		// Trigger sub menu
		menuExpandLinks.forEach(el => {
			el.addEventListener("click", e => {
				e.preventDefault();
				var elHeight = el.parentNode.parentNode.parentNode.scrollHeight;

				if (document.documentElement.clientWidth > 768){
					menuSubs.forEach(e => {
						e.classList.remove('active');
					});
				}				

				el.parentNode.parentNode
					.querySelector(".menu__sub")
					.classList.add("active");
				
				document.documentElement.style.setProperty(
					"--nav-background-height",
					el.parentNode.parentNode.parentNode.scrollHeight+16+'px' //1.6rem padding
				);				
			});
		});
	}
}

function menuSticky() {
	var siteHeader = document.querySelector(".site-header");

	if (siteHeader == undefined) {
		return;
	}

	var sticky = siteHeader.offsetHeight;

	window.onscroll = function() {
		if (window.pageYOffset >= sticky) {
			siteHeader.classList.add("fixed");
		} else {
			siteHeader.classList.remove("fixed");
		}
	};
}

function mobileSearchMenu() {
	var searchButton = document.querySelector(".search-button");

	searchButton.addEventListener("click" , function (){

		document.querySelector(".search-bar").classList.toggle("active");
	});
}

function logo() {
	var prodLogos = document.querySelectorAll('.products-logo');
	var siteLogos = document.querySelectorAll('.brand-logo');

	if (location.pathname.includes('/products/')) {
		prodLogos.forEach(el => el.classList.remove('hide'));
		siteLogos.forEach(el => el.classList.add('hide'));
	}
}

mobileSearchMenu();
menuToggle();
mobileMenu();
menuSticky();
logo();