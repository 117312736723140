import Swiper, { Navigation, Pagination } from "swiper";
import "swiper/css";
Swiper.use([Navigation, Pagination]);

// init Swiper:
var swiperEnvironment = Swiper;
var swiperPagination = document.querySelector(".cards__swiper-pagination");

var init = false;

var timeout = false;
var delay = 250;

if (document.querySelector(".cards__container--main")) {
  //initial creation of swipers
  if (window.innerWidth < 768) {
    swiperEnvironment = new Swiper(".cards__container--main", {
      direction: "horizontal",
      loop: true,
      allowSlidePrev: true,
      slidesPerView: "1.3",
      spaceBetween: 20,

      pagination: {
        el: ".cards__swiper-pagination",
        clickable: true,
      }
    });

    swiperPagination.classList.remove("hidden");

    init = true;
  }

  window.addEventListener("resize", function() {
    this.clearTimeout(timeout);
    timeout = this.setTimeout(resizeScreen, delay);
  });

  //create and destroy swipers at this breakpoint
  function resizeScreen() {
    if (window.innerWidth > 768) {
      if (init) {
        swiperEnvironment.destroy(false, true);
        swiperPagination.classList.add("hidden");
        init = false;
      }
    } else {
      if (!init) {
        swiperEnvironment = new Swiper(".cards__container--main", {
          direction: "horizontal",
          loop: true,
          allowSlidePrev: true,
          slidesPerView: "1.3",
          spaceBetween: 20,

          pagination: {
            el: ".cards__swiper-pagination",
            clickable: true,
          }
        });

        swiperPagination.classList.remove("hidden");
        init = true;
      }
    }
  }
}