(function () {
	let accordions = document.querySelectorAll('.accordion');

	if (accordions == undefined) { return }

	accordions.forEach(el => init(el));

	function init(a) {
		let accordionItems = a.querySelectorAll('.accordion__item');
		let accordionDesktop = a.querySelector('.accordion__desktop');

		if (accordionItems == undefined) {
			return;
		}

		accordionItems.forEach(el => {
			var btn = el.querySelector('.accordion__title');
			var media = el.querySelector('.accordion__media');

			btn.addEventListener('click', function () {
				var isActive = el.classList.contains('active');

				if (window.innerWidth > 768) {
					console.log('is active: ', isActive);

					if (!isActive) {
						closeAll(accordionItems);
						el.classList.add('active');

						if (accordionDesktop != undefined) {
							accordionDesktop.innerHTML = media.innerHTML;
						}
					}
				}
				else {
					el.classList.toggle('active');
				}				
			});
	});
}

	function closeAll(els) {
	els.forEach(el => el.classList.remove('active'));
}
}());