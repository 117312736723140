(function(){
	let tabs = document.querySelectorAll('.product-tabs__tab');
	let content = document.querySelectorAll('.tab');
	let images = document.querySelectorAll('.tab-img');

	if (tabs == undefined){
		return;
	}

	for (let i = 0; i < tabs.length; i++) {
		tabs[i].addEventListener('click', () => tabClick(i));
	}

	function tabClick(currentTab) {
		removeActive();
		tabs[currentTab].classList.add('active');
		content[currentTab].classList.add('active');
		if (images[currentTab] != undefined) {
			images[currentTab].classList.add('active');
		}
	}

	function removeActive() {
		for (let i = 0; i < tabs.length; i++) {
			tabs[i].classList.remove('active');
			content[i].classList.remove('active');
			if (images[i] != undefined) {
				images[i].classList.remove('active'); 
			}
		}
	}
}());