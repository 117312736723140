// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination } from 'swiper';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination]);

// init Swiper:
const swiper = new Swiper('.carousel-container', {
	// Optional parameters
    direction: "horizontal",
    loop: true,
    allowSlidePrev: true,
    slidesPerView: "1.3",
    spaceBetween: 20,
	breakpoints: {
		400: {
			slidesPerView: 1.3,
		},
		768: {
			slidesPerView: 3.5,
			spaceBetween: 30,
		},
	},

	// If we need pagination
	pagination: {
		el: '.products-swiper-pagination',
		clickable: true
	},
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	  },
});